/* ------------------------- Block Description -------------------------------------------------- */
.section {
  &.description {
    padding-bottom: calc(var(--section-padding) * 0.66);
  }

  &.description .flex-col:nth-child(1) {
    // width: 70%;
    width: 50%;
    padding-right: var(--gap-padding);
  }

  &.description :is(.flex-col):nth-child(2) {
    width: 50%; // 2nd flex col
  }

  & .single-about-image .overlay {
    background: center center no-repeat;
    background-size: cover;
    top: -10vh;
    height: 135%;
  }

  &.description :is(.flex-col) {
    padding-bottom: 5rem;
  }
}

@media screen and (max-width: 540px) {
  .description {
    overflow: hidden;

    & .single-about-image {
      width: 150%;
    }

    & .flex-col:nth-child(1) {
      width: 70% !important;
    }
  }
}
