/* ------------------------- Block Image -------------------------------------------------- */
.section {
  &.block-fullwidth {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: rgba(100, 100, 100, 0.1);
  }

  &.block-fullwidth .single-image {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  /* Make the image bigger (zoom/zoom-out)*/
  &.block-fullwidth .single-image::before {
    content: "";
    display: block;
    padding-top: 66%;
    /* padding-top: 76%; */
  }

  &.block-fullwidth :is(.overlay-image) {
    height: 120%;
    top: -10%;
  }

  &.block-fullwidth .text-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & img.overlay {
    object-fit: cover;
  }
}

// Mobile
@media screen and (max-width: 540px) {
  // Hide the large image
  .block-fullwidth {
    overflow: hidden;
  }

  .msft,
  .future {
    min-height: 30vh !important;
  }

  .block-fullwidth .text-overlay {
    text-align: center;
  }

  .block-fullwidth :is(.overlay-image) {
    height: 130%;
    top: -20%;
  }

  .future :is(.overlay-image) {
    height: 140%;
  }

  // Change based on image size
  .block-fullwidth :is(.overlay-image) {
    height: 130% !important;
    top: -0% !important;
  }

  .block-fullwidth {
    & .single-image::before {
      padding-top: 155% !important;
    }

    .single-image {
      height: 100vh;
      width: 250% !important;
    }

    & .text-overlay {
      width: 40%;
    }
  }
}
