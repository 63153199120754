.inner {
  background-color: var(--clr-white);
  .page {
    background-color: white;
  }
  /* Slider animation template */
  .slide {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: var(--clr-black);
    width: 100vw;
    height: 100vh;
  }
}
