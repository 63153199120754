:root {
  --section-padding: clamp(5em, 21vh, 12em);
  --container-padding: 5vw;
  --gap-padding: clamp(1.5em, 4vw, 2.5em);

  --title-size: clamp(5em, 7.5vw, 7.5em);

  --color-dark: #1c1d20;

  --clr-white: #fff;
  --clr-black: #1d1d1f;
  --clr-lightgray: #e9eaeb;
  --clr-transparent: rgba(255, 255, 255, 0.6);
  --clr-transparent-light: rgba(255, 255, 255, 0.4);
  --clr-transparent-full: rgba(255, 255, 255, 1);

  /* font-families */
  --ff-apple-normal: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;

  /* font-sizes */
  --fs-900: clamp(5rem, 8vw + 1rem, 9.375rem);
  --fs-800: clamp(3.25rem, 3rem + 2vw, 6.25rem);
  --fs-700: clamp(1.94rem, 1.25rem + 3.45vw, 3.71rem);
  --fs-650: clamp(1.5rem, 1.25rem + 2vw, 3rem);
  --fs-600: clamp(2.62rem, 1.23rem + 1.96vw, 2.62rem);
  --fs-500: clamp(1.35rem, 1.15rem + 0.99vw, 1.86rem);
  --fs-400: clamp(1.13rem, 1.05rem + 0.37vw, 1.31rem);
  --fs-300: clamp(1rem, 0.94rem - 0.02vw, 1.125rem);
  --fs-200: clamp(0.66rem, 0.83rem - 0.24vw, 0.78rem);
  --fs-logo: 300;
}

// Debug Purposes
// * {
//   background: #000 !important;
//   color: #0f0 !important;
//   outline: solid #f00 1px !important;
// }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

path,
i,
svg {
  pointer-events: none !important;
  /* Allows us to tap in the button without tapping in the actual img */
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

a {
  transition: all 250ms ease-in-out;
}

a {
  color: var(--color-blue);
  text-decoration: none;
}

ul,
li {
  list-style-type: none !important;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--ff-apple-normal);
  color: var(--clr-black);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

::-webkit-scrollbar {
  width: 0.2em;
}

::-webkit-scrollbar-track {
  background: black;
}

::-webkit-scrollbar-thumb {
  background-color: var(--clr-black);
  border-radius: 100vw;
}

/* ------------------------- Main Elements -------------------------------------------------- */
.section {
  display: block;
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);
  position: relative;
  min-height: 100vh;
  // border: 3px solid hotpink;
}

.section__title {
  text-align: center;
}

.container {
  margin: 0 auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  max-width: 100em;

  &.medium {
    padding-left: calc(var(--container-padding) * 2);
    padding-right: calc(var(--container-padding) * 2);
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.flex-col {
  display: block;
  width: 100%;
  order: 2;
  position: relative;
}

h3.big {
  font-size: calc(var(--title-size) * 0.45);
  line-height: 1.2;
}

h4 {
  font-weight: 400;
  width: 20ch;
}

.theme-dark h1,
.theme-dark h2,
.theme-dark h3,
.theme-dark h4,
.theme-dark h5,
.theme-dark p {
  color: var(--clr-white);
}

.logo {
  width: 230px;
  height: 25px;
  font-weight: 600;
}

/* take up the whole screen */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* ------------------------- Nav -------------------------------------------------- */
nav,
footer {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: var(--nav-padding, 2% 5%);
  min-height: var(--nav-height);
  background-color: transparent;
  z-index: 100;
}

.rounded-div-wrap {
  width: 100%;
  top: 0;
  position: relative;
  height: 10vh;
  overflow: hidden;
}

// The actual rounded div
.rounded-div {
  width: 150%;
  content: "";
  display: block;
  position: absolute;
  background: var(--color-white);
  height: 750%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -86.666%);
  z-index: 1;
}

/* ------------------------- Anim -------------------------------------------------- */
.animate-dot {
  opacity: 0;
  animation: animateDot1 2s linear infinite;
}

.animate-dot:nth-child(2) {
  animation: animateDot2 2s linear infinite;
}

.animate-dot:nth-child(3) {
  animation: animateDot3 2s linear infinite;
}

@keyframes animateDot1 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
}

@keyframes animateDot2 {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
}

@keyframes animateDot3 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
}

.span-lines .span-line {
  position: relative;
  display: inline-flex;
  overflow: hidden;
}

/* ------------------------- Utils -------------------------------------------------- */
.fs-900 {
  font-size: var(--fs-900);
}

.fs-800 {
  font-size: var(--fs-800);
}

.fs-700 {
  font-size: var(--fs-700);
}

.fs-650 {
  font-size: var(--fs-650);
}

.fs-600 {
  font-size: var(--fs-600);
}

.fs-550 {
  font-size: var(--fs-550);
}

.fs-500 {
  font-size: var(--fs-500);
}

.fs-400 {
  font-size: var(--fs-400);
}

.fs-300 {
  font-size: var(--fs-300);
}

.fs-200 {
  font-size: var(--fs-200);
}
