/* ------------------------- Loading Screen -------------------------------------------------- */

/* optional*/
.no-scroll-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 900;
  display: none;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 800;
  pointer-events: none;
  background: transparent;
}

.loading-screen {
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;
  left: 0;
  will-change: top;
}

.loading-screen,
.loading-container .rounded-div-wrap.top .rounded-div,
.loading-container .rounded-div-wrap.bottom .rounded-div {
  background: var(--clr-black);
}

.loading-container .rounded-div-wrap.top .rounded-div,
.loading-container .rounded-div-wrap.bottom .rounded-div {
  height: 745%;
}

.loading-container .rounded-div-wrap.top {
  top: 0;
  position: absolute;
  transform: translateY(-99%) scale(1, -1); /* go up and flip */
  z-index: 2;
  height: 0vh; /* initially set to no view */
  will-change: height;
}

.loading-container .rounded-div-wrap.bottom {
  top: unset;
  bottom: 0;
  position: absolute;
  transform: translateY(99%); /* translate down*/
  z-index: 2;
  height: 0vh;
  will-change: height;
}

// for loading words specifically
.loading-container .loading-words {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  display: block;
  will-change: opacity;
}

.loading-container .loading-words h2 {
  color: whitesmoke;
  position: absolute;
  transform: translateX(-50%);
  display: none; // initially
  will-change: opacity;
}

/* .loading-container .loading-words h2.jap {
    font-size: calc(clamp(3.25em, 5vw, 4.5em) * .65);
    transform: translate(-50%, 10%);
 }
 
 .loading-container .dot {
    display: block;
    position: absolute;
    border-radius: 50%;
    background: var(--color-white);
    left: 0;
    top: 47%;
    bottom: auto;
    width: calc(clamp(16px, 1.2vw, 19px) / 1.65);
    height: calc(clamp(16px, 1.2vw, 19px) / 1.65);
    transform: translate(0, 0) scale(1);
 } */

.loading-container .loading-words h2.active {
  display: block;
}

.loading-container .loading-words .home-active,
.loading-container .loading-words .home-active-last {
  opacity: 0;
  display: none;
}

.once-in {
  will-change: transform;
}

@media screen and (max-width: 540px) {
  .loading-container .dot {
    top: 42%;
  }
}
